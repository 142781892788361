<template>
    <div>
        <modal :name="modal_name" class="final-modal increase-width-popups-variants-addons popup-modal-all-width-height" :class="{'modalZindex':safariTrue,'modal-fix-width':loggedInUser.role == 'customer','select-addons-modal':loggedInUser.role != 'customer'}" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                        <span class="v-modal-dialog-title v-modal-title text-white">Edit Modifiers</span>
                        <span @click="hideModifierPopup()">
                        <i class="icon icon-close pointer text-white"></i>
                        </span>
                </div>
                <div class="general-section mod-popup h-100" style="padding: 15px 0px;">
                    <div v-if="api_call_in_progress" class="v-modal-body text-center">
                        <div class="digi-table-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div v-else class="v-modal-body">
                        <div class="newBox pl-3">
                            <h1 class="fw-bold" style="color: #206ad3; font-weight: 600; font-size: 16px;">Primary Modifiers</h1>
                            <div >
                                <div class="row">
                                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-for="(pm,pm_index) in product.pm_list" :key="pm_index">
                                        <div class="content-primary-modifier form-check-label p-2 mt-2" :class="pm.show_items == true ? 'primary-modifier-active' : ''" :title="pm.group_name">
                                            <div>
                                                <avatar v-if="pm.image == '' || pm.image == null || !pm.hasOwnProperty('image')"
                                                    :username="pm.group_name.trim()"
                                                    :src="''"
                                                    :size="100"
                                                    :rounded="true"
                                                    style="width: 50px !important; height: 50px !important; margin-left: auto !important; margin-right: auto !important;"
                                                    color="#000"
                                                ></avatar>
                                                <img v-else :src="pm.image" alt="" width="50" height="50">
                                            </div>
                                            <input type="radio"
                                                class="form-check-input position-absolute pointer" style="left: 28px;top: 8px;width: 90%;height: 86%;" @change="selectPrimaryModifier($event, pm,pm_index)"
                                                v-model="pm.show_items" name="modifier_name" />
                                            <span class="radio-icon d-none"></span>
                                            <div style="margin:auto 0; padding-left: 15px;overflow: hidden;text-overflow: ellipsis;">
                                                <span class="fs-15">{{pm.group_name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div v-for="(pm,pm_index) in product.pm_list" :key="pm_index">
                                        <div v-if="pm_index > 0 && pm.show_items">
                                            <div v-if="show_items_directly && pm.sm_items_list.length > 0">
                                                <h1 class="fw-bold pt-3" style="color: #6c6c6c; font-weight: 600; font-size: 16px;">Item</h1>
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-for="(sm_item, sm_item_index) in pm.sm_items_list" :key="sm_item_index+'mmindexddff2'">
                                                        <div class="item-content p-2 mt-2" :class="{'item-active': sm_item.is_selected_from_fe == true,'image-unavailable': sm_item.is_blocked}" :title="sm_item.name">
                                                            <!-- <p class="unavailable-image" v-if="sm_item.is_blocked">Unavailable</p> -->
                                                            <span class="text-danger unavail" v-if="sm_item.is_blocked">
                                                                Unavailable
                                                            </span>
                                                            <div>
                                                                <avatar v-if="sm_item.image == '' || sm_item.image == null || !sm_item.hasOwnProperty('image')"
                                                                    :username="sm_item.name.trim()"
                                                                    :src="''"
                                                                    :size="100"
                                                                    :rounded="true"
                                                                    style="width: 50px !important; height: 50px !important; margin-left: auto !important; margin-right: auto !important;"
                                                                    color="#000"
                                                                ></avatar>
                                                                <img v-else :src="sm_item.image" alt="" width="50" height="50">
                                                            </div>
                                                            <input type="checkbox"
                                                                class="form-check-input position-absolute pointer" style="left: 28px;top: 8px;width: 90%;height: 86%;" @change="selectDirectModifierItem($event, sm_item,sm_item_index,pm.sm_items_list,pm)"
                                                                v-model="sm_item.is_selected_from_fe" name="modifier_name" />
                                                            <span class="radio-icon d-none"></span>
                                                            <div style="margin:auto 0; padding-left: 15px;width:100%;   text-overflow: ellipsis;overflow: hidden;">
                                                                <div class="text-center" style="text-overflow: ellipsis;overflow: hidden">
                                                                    <span class="fs-15">{{sm_item.name}}</span>
                                                                </div>
                                                                <div v-if="sm_item.has_price" class="text-center">
                                                                    <span class="fs-15">{{sm_item.price}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="pm_index == 0 && pm.hasOwnProperty('selection_list') && pm.show_items">
                                            <div >
                                                <h1 class="fw-bold pt-3" style="color: #6c6c6c; font-weight: 600; font-size: 16px;">Select Option</h1>
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-for="(pm_option, pm_option_index) in pm.selection_list" :key="pm_option_index+'mmindexddff'">
                                                        <div class="options-content mt-2 text-center" :class="{'option-active': pm_option.is_selected_from_fe == true,'image-unavailable': pm_option.is_blocked}" :title="pm_option.name">
                                                            <span class="text-danger unavailoption" v-if="pm_option.is_blocked">
                                                                Unavailable
                                                            </span>
                                                            <input type="radio"
                                                                class="form-check-input position-absolute pointer" style="left: 28px;top: 8px;width: 90%;height: 86%;" @change="selectOption($event, pm_option,pm_option_index,pm.selection_list)"
                                                                v-model="pm_option.is_selected_from_fe" name="modifier_name" />
                                                            <span class="radio-icon d-none"></span>
                                                            <div style="text-overflow: ellipsis;overflow: hidden">
                                                                <span class="head">{{pm_option.name}}</span>
                                                            </div>
                                                            <span v-if="parseFloat(pm_option.price.substring(1,)) > 0" class="price">{{pm_option.price}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="show_secondary">
                                                <h1 class="fw-bold pt-3" style="color: #206ad3; font-weight: 600; font-size: 16px;">Secondary Modifiers</h1>
                                                <div v-if="ModifierCallInProgress" class="d-flex justify-content-center">
                                                        <div class="digi-table-loader">
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                </div>
                                                <div v-if="!ModifierCallInProgress && secondary_modifiers.length < 1" class="d-flex justify-content-center">
                                                        <div class="fs-15">No Secondary Modifiers</div>
                                                </div>
                                                <div v-else-if="!ModifierCallInProgress && secondary_modifiers.length > 0">
                                                        <div style="overflow-x: hidden;overflow-y: auto;" @scroll="scrollEndSModifiers">
                                                            <div class="row">
                                                                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 position-relative" v-for="(sec_mod, sec_mod_index) in secondary_modifiers" :key="sec_mod_index">
                                                                    <div class="content-secondary-modifier p-2 mt-2" :class="sec_mod.is_selected_from_fe == true ? 'secondary-modifier-active':''" :title="sec_mod.name">
                                                                        <div class="selected-img-badges" v-if="sec_mod.is_selected" style="top:0!important">
                                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/selected_icon.svg">
                                                                        </div>
                                                                        <div>
                                                                            <avatar v-if="sec_mod.image == '' || sec_mod.image == null || !sec_mod.hasOwnProperty('image')"
                                                                                :username="sec_mod.name.trim()"
                                                                                :src="''"
                                                                                :size="100"
                                                                                :rounded="true"
                                                                                style="width: 50px !important; height: 50px !important; margin-left: auto !important; margin-right: auto !important;"
                                                                                color="#000"
                                                                            ></avatar>
                                                                            <img v-else :src="sec_mod.image" alt="" width="50" height="50">
                                                                        </div>
                                                                        <input type="checkbox"
                                                                            class="form-check-input position-absolute pointer" style="left: 28px;top: 8px;width: 90%;height: 86%;" @change="selectSecondaryModifier($event, sec_mod,sec_mod_index)"
                                                                            v-model="sec_mod.is_selected_from_fe" name="modifier_name" />
                                                                        <span class="radio-icon d-none"></span>
                                                                        <div class="d-flex align-items-start" style="margin:auto 0; padding-left: 15px;text-overflow: ellipsis;overflow: hidden">
                                                                            <span class="fs-15 text-truncate">
                                                                                {{sec_mod.name}}
                                                                            </span>
                                                                            <span class="mandatory_star" v-if="sec_mod.mandatory_options_to_be_selected > 0">
                                                                                &#9733;
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-for="(sec_mod, sec_mod_index) in secondary_modifiers" :key="sec_mod_index">
                                                            <div v-if="sec_mod.is_selected_from_fe && sec_mod.sm_item_list.length > 0 ">
                                                                <h1 class="fw-bold pt-3" style="color: #6c6c6c; font-weight: 600; font-size: 16px;">
                                                                    Item - {{sec_mod.name}} <span v-if="sec_mod.mandatory_options_to_be_selected > 0">(Minimum {{sec_mod.mandatory_options_to_be_selected}} {{sec_mod.mandatory_options_to_be_selected == 1 ? 'item' : 'items'}} must be selected)</span>
                                                                    <!-- <span>(Maximum 3 items can be selected)</span> -->
                                                                </h1>
                                                                <div class="row">
                                                                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-for="(sm_item, sm_item_index) in sec_mod.sm_item_list" :key="sm_item_index+'mmindexddff1'">
                                                                        <div class="item-content p-2 mt-2" :class="{'item-active': sm_item.is_selected_from_fe == true,'image-unavailable': sm_item.is_blocked}" :title="sm_item.name">
                                                                            <!-- <p class="unavailable-image" v-if="sm_item.is_blocked">Unavailable</p> -->
                                                                            <span class="text-danger unavail" v-if="sm_item.is_blocked">
                                                                                Unavailable
                                                                            </span>
                                                                            <div>
                                                                                <avatar v-if="sm_item.image == '' || sm_item.image == null || !sm_item.hasOwnProperty('image')"
                                                                                    :username="sm_item.name.trim()"
                                                                                    :src="''"
                                                                                    :size="100"
                                                                                    :rounded="true"
                                                                                    style="width: 50px !important; height: 50px !important; margin-left: auto !important; margin-right: auto !important;"
                                                                                    color="#000"
                                                                                ></avatar>
                                                                                <img v-else :src="sm_item.image" alt="" width="50" height="50">
                                                                            </div>
                                                                            <input type="checkbox"
                                                                                class="form-check-input position-absolute pointer" style="left: 28px;top: 8px;width: 90%;height: 86%;" @change="selectSecondaryModifierItem($event, sm_item,sm_item_index,sec_mod.sm_item_list, sec_mod)"
                                                                                v-model="sm_item.is_selected_from_fe" name="modifier_name" />
                                                                            <span class="radio-icon d-none"></span>
                                                                            <div style="margin:auto 0; padding-left: 15px;overflow: hidden">
                                                                                <div class="text-center" style="overflow:hidden;text-overflow: ellipsis;">
                                                                                    <span class="fs-15" >{{sm_item.name}}</span>
                                                                                </div>
                                                                                <div v-if="sm_item.has_price" class="text-center">
                                                                                    <span class="fs-15">
                                                                                        <!-- <span>currency</span> -->
                                                                                        {{sm_item.price}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                                <div v-else-if="ModifierCallInProgress == false && !secondary_modifiers" class="text-center">
                                                    <span class="fs-15">No Data</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class ="newBox mt-4">
                            <div class="pointer" @click="expandNotes()" >
                                <span class="collapse-addon">
                                    <i v-if="showNotes == true" class="icon-chevron-double-up icon-style mt-1"></i>
                                    <i v-else class="icon-chevron-double-down icon-style mt-1"></i>
                                </span>
                                <span class="d-flex text-secondary font-weight-bold fs-16 mt-2 border-notes">Notes</span>
                                <hr class="ml-14 addon-border mr-8">
                            </div>
                            <textarea v-if="showNotes == true" class="form-control addon-note mt-2" v-model="note" v-on:input="check"></textarea>
                            <div>
                                <p v-if="showNotes == true" class="text-right text-grey font-600 fs-12 pt-1 mb-0">
                                <!-- {{noteLength - note.length}} / {{noteLength}}</p> -->
                                    {{instruction}} / 200 </p>
                            </div>
                        </div>
                        <div class="d-flex mt-4">
                            <p class="mb-0 pr-3 text-secondary fs-15">Summary</p>
                            <div class="mb-2 border-line"></div>
                        </div>
                        <div class="total-addon row no-gutters align-items-center p-2 mt-4">
                            <table class="w-100">
                                <thead >
                                    <tr class="w-100" >
                                        <th class="px-4 py-3" style="border-bottom:0.7px solid #ffffff; width: 33.333%;">
                                            <span class="text-white font-weight-bold">{{product.product_name}}</span>
                                        </th>
                                        <th class="px-4 py-3 whiteSpace" style="border-bottom:0.7px solid #ffffff;width: 33.333%;">
                                            <div class="input-prepend-append d-flex pl-1 pr-1 align-items-center justify-content-start mx-auto">
                                                <a class="btn-prepend pt-1 pointer disableminus" >
                                                    <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg" style="width:25px;">
                                                </a>
                                                <input oncopy="return false" style="border-radius: 5px !important; color: #fff !important;" onpaste="return false" readonly v-validate="{required:true}" name="item_qty" v-model.number="product.quantity" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" type="number" min="1" class="form-control text-center add-custom-addon">
                                                <a class="btn-append pt-1 pointer disableminus">
                                                    <img src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" style="width:25px;">
                                                </a>
                                                <div class="text-white ml-2" style="visibility: hidden;">(Max. Qty - 0)</div>
                                            </div>
                                        </th>
                                        <th class="px-4 py-3 whiteSpace" style="border-bottom:0.7px solid #ffffff;width: 33.333%;">
                                            <span class="text-white font-weight-bold d-flex align-items-center justify-content-end">Total Price: {{product.pm_list[0].selection_list[0].price.substring(0,1)}} {{this.formatPrice(productPrice)}}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="mt-3">
                                    <tr style="height: 0 !important;">
                                        <td class="px-4 py-0 pt-3 w-100" colspan="3">
                                            <span class="text-white font-weight-bold d-flex align-items-center">Modifiers:</span>
                                        </td>
                                    </tr>
                                    <tr style="height: 0 !important;" v-for="(sel_mod, sel_mod_index) in selected_modifiers" :key="sel_mod_index">
                                        <td class="px-4 py-1" style="width: 33.333%;" >
                                            <div class="d-flex justify-content-between mb-2">
                                                <div class="d-flex align-items-center w-150px">
                                                    <span class="text-white">{{sel_mod.name}}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-4 py-1 whiteSpace" style="width: 33.333%;">
                                            <div class="input-prepend-append d-flex pl-1 pr-1 align-items-center justify-content-start mx-auto" v-if="sel_mod.has_price">
                                                <a class="btn-prepend pt-1 pointer" :class="sel_mod.selected_qty<=1 ? 'disableminus' :  ''" @click="decreaseModifierQuantity(sel_mod)">
                                                    <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg" style="width:25px;">
                                                </a>
                                                <input oncopy="return false" style="border-radius: 5px !important; color: #fff !important;" onpaste="return false" v-validate="{required:true}" readonly name="item_qty" v-model.number="sel_mod.selected_qty" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" type="number" min="1" class="form-control text-center add-custom-addon">
                                                <a class="btn-append pt-1 pointer" @click="increaseModifierQuantity(sel_mod)" :class="sel_mod.selected_qty>=sel_mod.max_quantity ? 'disableminus' :  ''">
                                                    <img src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" style="width:25px">
                                                </a>
                                                <div class="text-white ml-2">(Max. Qty - {{sel_mod.max_quantity}})</div>
                                            </div>
                                        </td>
                                        <td class="px-4 py-1 whiteSpace" style="width: 33.333%;">
                                            <div class="d-flex align-items-center justify-content-end" v-if="sel_mod.has_price">
                                                <span class="text-white font-weight-bold d-flex align-items-center">{{sel_mod.price}}</span>
                                                <div class="save-addon d-flex align-items-center ml-2">
                                                    <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                        <span class="ml-3 pointer" @click="deleteModifier(sel_mod_index,sel_mod.sm_item_id)">
                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/trash_addon.svg">
                                                        </span>
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center d-block" style="padding-top:0 !important;">
                    <div class="row mt-0">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <button @click="AdditemToOrder" class="pointer w-100 d-flex justify-content-between align-items-center bg-blue-image p-3 bg-orange-image mt-3 b-r-7">
                                    <span class="font-weight-bold fs-16 text-darker ">Save</span>
                                    <span>
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/save-arrow.svg">
                                    </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <div v-if="modifiers_save_api_call_inprogress" class="full-page-loader">
            <div class="d-flex align-items-center justify-content-center">
                <div class="digi-table-loader inverse">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
        <sweet-modal ref="success_modal" icon="success">
            {{ modal_msg }}
        </sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">
            {{ modal_msg }}
        </sweet-modal>
    </div>
</template>

<script>
import Avatar from 'vue-avatar'
import orderServices from './mixins/orders'
import { SweetModal } from "sweet-modal-vue";
export default {
    mixins:[orderServices],
    props:['modal_name','order_data', 'item_id', 'product'],
    data() {
        return{
            safariTrue:false,
            showNotes: false,
            noteLength: 200,
            limit: 200,
            modal_msg: '',
            note: '',
            selected_pmid: '',
            // items_qty: 1,
            modifier_items_qty: 1,
            secondary_modifiers: [],
            selected_modifiers:[],
            selected_modifiers1:[],
            show_modifier_options: false,
            show_secondary_modifier_options: false,
            ModifierCallInProgress: false,
            show_items_directly: false,
            modifiers_total: 0,
            show_secondary: false,
            indirect_modifiers: [],
            direct_modifiers: [],
            api_call_in_progress: false,
            stored_secondary_modifiers: [],
            smodifiers_scroll_count: 0,
            smodifiers_scroll_end: false,
            modifiers_save_api_call_inprogress: false
        }
    },
    components: {
        SweetModal,
        Avatar
    },
    methods:{
        increaseQuantity(){
            this.product.quantity += 1
        },
        decreaseQuantity(){
            if(this.product.quantity > 1){
                this.product.quantity -= 1
            } else {
                this.product.quantity = 1
            }
            // this.product.quantity -= 1
        },
        increaseModifierQuantity(x){
            if(x.selected_qty < x.max_quantity){
                x.selected_qty += 1
            }
        },
        decreaseModifierQuantity(x){
            if(x.selected_qty > 1){
                x.selected_qty -= 1
            } else {
                x.selected_qty = 1
            }
            // x.selected_qty -= 1
        },
        hideModifierPopup(){
            this.$emit('hideModifierpopup')
        },
        expandNotes(){
            if(!this.showNotes){
                this.showNotes = true;
            }
            else{
                this.showNotes = false;
            }
        },
        selectPrimaryModifier(event, pm,pm_index){
            this.product.pm_list.forEach((el,index) => {
                el.show_items = false
            })
            if(pm.hasOwnProperty('selection_list')){
                this.show_modifier_options = true
                this.show_items_directly = false
                this.show_secondary = true
            }else{
                this.show_secondary = false
                this.show_secondary_modifier_options = false
                this.show_modifier_options = false
                this.show_items_directly = true
            }
            this.product.pm_list[pm_index].show_items = true;
            this.$forceUpdate();
        },
        selectOption(event, option, option_index, array){
            array.forEach((el, index) => {
                el.is_selected_from_fe = false
            })
            array[option_index].is_selected_from_fe = true
            this.show_secondary = true
            this.selected_pmid = array[option_index].pmid 
            this.$forceUpdate();
            this.getSecondaryModifierDetails()
        },
        async getSecondaryModifierDetails(){
            this.secondary_modifiers = []
            this.smodifiers_scroll_count = 0;
            this.ModifierCallInProgress = true
            let params = {
                pid: this.product.product_id,
                order_id: this.order_data._id,
                pmid: this.selected_pmid,
                skip: 0,
                limit: 10
            }
            let response = await this.getSecondaryMod(params)
            this.secondary_modifiers = response.response
            this.indirect_modifiers.forEach((el, index) => {
                const index1 = this.selected_modifiers.map(object => object.sm_item_id).indexOf(el.sm_item_id);
                this.selected_modifiers.splice(index1, 1);
            })
            this.indirect_modifiers = [];
            this.smodifiers_scroll_count = response.response.length
            this.ModifierCallInProgress = false
        },
        async scrollEndSModifiers(e) {
            const {target} = e;
            if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
                //this code will run when the user scrolls to the bottom of this div so
                //you could do an api call here to implement lazy loading
                this.smodifiers_scroll_end = true
                let params = {
                        pid: this.product.product_id,
                        order_id: this.order_data._id,
                        pmid: this.selected_pmid,
                        skip: this.smodifiers_scroll_count,
                }
                let response = await this.getSecondaryMod(params)
                if (response.payload.skip == this.smodifiers_scroll_count) {
                        let tempFiles = response.response
                        tempFiles.forEach(el => {
                            this.secondary_modifiers.push(el)
                        })
                        // this.indirect_modifiers.forEach((el, index) => {
                        //     const index1 = this.selected_modifiers.map(object => object.sm_item_id).indexOf(el.sm_item_id);
                        //     this.selected_modifiers.splice(index1, 1);
                        // })
                        let new_filter_s_modifiers = JSON.parse(JSON.stringify(this.secondary_modifiers))

                        const secondary_modifiers = Array.from(new Set(new_filter_s_modifiers.map(a => a.smid)))
                        .map(smid => {
                            return new_filter_s_modifiers.find(a => a.smid === smid)
                        })
                        this.secondary_modifiers = secondary_modifiers
                        // this.indirect_modifiers = []
                        this.smodifiers_scroll_count += response.response.length
                        this.smodifiers_scroll_end = false
                }
                if (response.response.length < 10) {
                        // document.getElementById('spinner').style.display = 'none'
                        this.smodifiers_scroll_end = false
                }
            }
        },
        async AdditemToOrder(){
            try {
                this.modifiers_save_api_call_inprogress = true;
                this.selected_modifiers.forEach((sel, sel_index) => {
                    this.direct_modifiers.forEach((el, el_index) => {
                        if(el.m_item_id == sel.sm_item_id){
                            el.price = sel.price,
                            el.quantity = sel.selected_qty <=0 ? 1 : sel.selected_qty
                        }
                    })
                    this.indirect_modifiers.forEach((el, el_index) => {
                        if(el.m_item_id == sel.sm_item_id){
                            el.price = sel.price,
                            el.quantity = sel.selected_qty <=0 ? 1 : sel.selected_qty
                        }
                    })
                })
                let is_all_mandatory_mod_selected_from_fe = true
                this.secondary_modifiers.forEach((sec_mod, sec_mod_index) => {
                    if(sec_mod.mandatory_options_to_be_selected > 0) {
                        let is_all_mandatory_mod_selected_count = 0
                        sec_mod.sm_item_list.forEach((sm_item, sm_index) => {
                            if(sm_item.hasOwnProperty('is_selected_from_fe')) {
                                    if(sm_item.is_selected_from_fe == true) {
                                        is_all_mandatory_mod_selected_count += 1
                                    }
                            }
                        });
                        if(is_all_mandatory_mod_selected_count < sec_mod.mandatory_options_to_be_selected) {
                            this.$toasted.global.error(`Please select minimum ${sec_mod.mandatory_options_to_be_selected} item modifiers from ${sec_mod.name}`);
                            is_all_mandatory_mod_selected_from_fe = false
                            return false
                        }
                    }
                })
                if(is_all_mandatory_mod_selected_from_fe) {
                    let params = {
                        order_id: this.order_data._id,
                        product_id: this.product.product_id,
                        item_id: this.product.order_item_id,
                        modifiers: {
                            for_primary: this.direct_modifiers,
                            for_secondary: this.indirect_modifiers
                        },
                        quantity: this.product.quantity,
                        course: this.product.course,
                        note: this.note,
                        is_customizable: this.product.is_customizable,
                        is_combo: this.product.is_combo,
                        variant_id: this.selected_pmid
                    }
                    let response = await this.addItemToOrderCustomize(params)
                    this.modal_msg = response.message;
                    this.$toasted.global.success(this.modal_msg);
                    this.$emit('updateModifierSuccess')
                    this.modifiers_save_api_call_inprogress = false;
                } else {
                    this.modifiers_save_api_call_inprogress = false;
                    return false;
                }
            }
            catch(err) {
                this.$toasted.global.error(err);
            }
        },
        selectSecondaryModifier(event, sec_mod, sec_mod_index){
            this.secondary_modifiers.forEach((el,index) => {
                el.is_selected_from_fe = false
            })
            this.show_secondary_modifier_options = true
            this.secondary_modifiers[sec_mod_index].is_selected_from_fe = true;
            this.$forceUpdate();
        },
        selectSecondaryModifierItem(event, sm_item,sm_item_index,array,sec_mod){
            if(event.target.checked == true){
                this.selected_modifiers.push({
                    name: array[sm_item_index].name,
                    price: array[sm_item_index].price,
                    has_price: array[sm_item_index].has_price,
                    selected_qty: array[sm_item_index].selected_qty <= 0 ? 1 : array[sm_item_index].selected_qty,
                    max_quantity: array[sm_item_index].max_quantity_to_be_selected,
                    quantity: array[sm_item_index].quantity,
                    sm_item_id: array[sm_item_index].sm_item_id,
                })
                this.indirect_modifiers.push({
                    m_id: sec_mod.smid,
                    m_item_name: array[sm_item_index].name,
                    m_name: sec_mod.name,
                    m_item_id : array[sm_item_index].sm_item_id,
                    amount: array[sm_item_index].price,
                    price: array[sm_item_index].price,
                    max_quantity: array[sm_item_index].max_quantity_to_be_selected,
                    has_price: parseFloat(array[sm_item_index].price.slice(1,)) > 0 ? true : false,
                    quantity: array[sm_item_index].selected_qty <= 0 ? 1 : array[sm_item_index].selected_qty
                })

            } else {
                const index = this.selected_modifiers.map(object => object.sm_item_id).indexOf(array[sm_item_index].sm_item_id);
                this.selected_modifiers.splice(index, 1);
                const index1 = this.indirect_modifiers.map(object => object.m_item_id).indexOf(array[sm_item_index].sm_item_id);
                this.indirect_modifiers.splice(index1, 1);
            }
            sec_mod.is_selected = false
            // mandatory_options_to_be_selected
            sec_mod.sm_item_list.forEach((sm_item, sm_item_index) => {
                if(sm_item.is_selected_from_fe == true) {
                        sec_mod.is_selected = true
                }
            })
            this.$forceUpdate()
        },
        deleteModifier(index,id){
            this.selected_modifiers.splice(index,1)
            this.secondary_modifiers.forEach((el, el_index) => {
                el.sm_item_list.forEach((sm, sm_index) => {
                    if(id == sm.sm_item_id){
                        sm.is_selected_from_fe = false
                    }
                })
            })
            this.product.pm_list.forEach((el, el_index) => {
                if(el.hasOwnProperty('sm_item_list')){
                    el.sm_item_list.forEach((sm, sm_index) => {
                        if(id == sm.sm_item_id){
                            sm.is_selected_from_fe = false
                        }
                    })
                }
            })
            let indirect_mod_index = this.indirect_modifiers.findIndex((data) => data.sm_item_id == id);
            this.indirect_modifiers.splice(indirect_mod_index, 1);
            let direct_mod_index = this.direct_modifiers.findIndex((data) => data.sm_item_id == id);
            this.direct_modifiers.splice(direct_mod_index, 1)
            // this.$forceUpdate()
        },
        selectDirectModifierItem(event, sm_item,sm_item_index,array,pm){
            if(event.target.checked == true){
                this.selected_modifiers.push({
                    name: array[sm_item_index].name,
                    has_price: array[sm_item_index].has_price,
                    price: array[sm_item_index].price,
                    sm_item_id: array[sm_item_index].sm_item_id,
                    max_quantity: array[sm_item_index].max_quantity_to_be_selected,
                    selected_qty: array[sm_item_index].selected_qty,
                    quantity: array[sm_item_index].quantity
                })
                this.direct_modifiers.push({
                    m_id: pm.smid,
                    m_item_name: array[sm_item_index].name,
                    m_name: pm.name,
                    m_item_id : array[sm_item_index].sm_item_id,
                    price: array[sm_item_index].price,
                    amount: array[sm_item_index].price,
                    max_quantity: array[sm_item_index].max_quantity_to_be_selected,
                    has_price: parseFloat(array[sm_item_index].price.slice(1,)) > 0 ? true : false,
                    quantity: array[sm_item_index].selected_qty
                })
            } else {
                const index = this.selected_modifiers.map(object => object.sm_item_id).indexOf(array[sm_item_index].sm_item_id);
                this.selected_modifiers.splice(index, 1);
                const index1 = this.direct_modifiers.map(object => object.m_item_id).indexOf(array[sm_item_index].sm_item_id);
                this.direct_modifiers.splice(index1, 1);
            }
            this.$forceUpdate()
        },
        formatPrice(value){
            let val = (value / 1).toFixed(2)
            return val
        },
        async getEditData() {
            try {
                this.api_call_in_progress = true
                this.selected_modifiers = []
                let params = {
                    skip: 0,
                    item_id: this.item_id
                };
                let response = await this.getSelectedModifiersData(params)
                if(response.response.pm_list) {
                    if(response.response.pm_list.length > 0) {
                        this.product['pm_list'] = response.response.pm_list
                    } else {
                        this.product['pm_list'] = []
                    }
                }
                if(response.response.sm_list) {
                    if(response.response.sm_list.length > 0) {
                        this.secondary_modifiers = response.response.sm_list
                    } else {
                        this.secondary_modifiers = []
                    }
                }
                this.product.pm_list.forEach((el, el_index) => {
                    el.show_items = false
                })
                this.product.pm_list[0].show_items = true
                if(this.product.pm_list[0].hasOwnProperty('selection_list')){
                    this.show_modifier_options = true
                    this.show_items_directly = false
                    this.show_secondary = true
                    this.product.pm_list[0].selection_list.forEach((el, el_index) => {
                        if(el.is_selected) {
                            el['is_selected_from_fe'] = true
                            this.selected_pmid = el.pmid
                        }
                    })
                }
                this.product.pm_list.forEach((pm, pm_index)=> {
                    if(pm.hasOwnProperty('sm_items_list')) {
                        this.show_items_directly = true
                        pm.sm_items_list.forEach((sm_item, sm_item_index) => {
                            if(sm_item.is_selected == true) {
                                sm_item['is_selected_from_fe'] = true
                                sm_item.selected_qty = sm_item.selected_qty <= 0 ? 1 : sm_item.selected_qty
                                this.direct_modifiers.push({
                                    m_id: pm.smid,
                                    m_item_name: sm_item.name,
                                    m_name: pm.group_name,
                                    m_item_id: sm_item.sm_item_id,
                                    price: sm_item.price,
                                    has_price: sm_item.has_price,
                                    amount: sm_item.price,
                                    max_quantity: sm_item.max_quantity_to_be_selected,
                                    quantity: sm_item.quantity,
                                })
                                this.selected_modifiers.push(sm_item)
                            }
                        })
                    }
                })
                this.secondary_modifiers.forEach((sm, sm_index)=> {
                    if(sm.hasOwnProperty('sm_item_list')) {
                        this.show_items_directly = true
                        sm.sm_item_list.forEach((sm_item, sm_item_index) => {
                            if(sm_item.is_selected == true) {
                                sm_item['is_selected_from_fe'] = true
                                sm_item.selected_qty = sm_item.selected_qty <= 0 ? 1 : sm_item.selected_qty
                                this.indirect_modifiers.push({
                                    m_id: sm.smid,
                                    m_item_name: sm_item.name,
                                    m_name: sm.name,
                                    m_item_id: sm_item.sm_item_id,
                                    has_price: sm_item.has_price,
                                    price: sm_item.price,
                                    amount: sm_item.price,
                                    max_quantity: sm_item.max_quantity_to_be_selected,
                                    quantity: sm_item.quantity,
                                })
                                this.selected_modifiers.push(sm_item)
                            }
                        })
                    }
                })
                this.selected_modifiers.forEach((sel_mod, sel_mod_index) => {
                    sel_mod['max_quantity'] = sel_mod.max_quantity_to_be_selected
                })
                this.api_call_in_progress = false
            }
            catch (error) {
                // console.log(error)
                this.$toasted.global.error(error);
            }
        },
        check: function() {
            this.note = this.note.substr(0, this.limit)
        }
    },
    computed:{
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        productPrice(){
            return this.product.quantity * (this.modifierPrice + this.product.product_price_exclusive_unformatted)
        },
        modifierPrice(){
            return this.selected_modifiers.reduce((total, modifier) => total += parseFloat(modifier.selected_qty * modifier.price.slice(1,)), 0)
        },
        instruction: function() {  
               return this.note==''?
                    ''+this.limit+'':
                    ''+this.remaining+'';      
          },
          remaining: function() {
               return this.limit-this.note.length;
          }
    },
    created(){
    },
    mounted(){
        this.getEditData();
        this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
}

</script>

<style scoped>
    .text-right{
        text-align: right;
    }
    .newBox {
        box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
        background: #fff;
        padding: 10px;
        border-radius: 8px;
        margin-top: 5px;
        margin-bottom: 5px;
        border: 1px solid #00448b;
    }
    .mod-popup .content-primary-modifier{
        border: 1px solid #b2b2b2;
        display: flex;
        border-radius: 5px !important;
    }
    .mod-popup .content-primary-modifier.primary-modifier-active{
        border: 2px solid #033a88;
        font-weight: bold
    }
    .mod-popup .content-secondary-modifier{
        border: 1px solid #0094e0;
        color: #0094e0;
        display: flex;
        border-radius: 5px !important;
    }
    .mod-popup .content-secondary-modifier.secondary-modifier-active{
        border: 1px solid #009f51;
        background-color: #009f51;
        color: #fff;
        font-weight: bold
    }
    .mod-popup .item-content{
        border: 1px solid #b2b2b2;
        display: flex;
        border-radius: 5px !important;
    }
    .mod-popup .item-content.item-active{
        border: 1px solid #206ad3;
        background-color: #d2e8fd;
        font-weight: bold;
    }
    .mod-popup .fs-15{
        font-size: 15px
    }
    .mod-popup .options-content{
        border: 1px dashed #b2b2b2;
        padding: 10px;
        border-radius: 5px !important;
    }
    .mod-popup .options-content .head{
        font-size: 15px
    }
    .mod-popup .options-content .price{
        font-size: 12px;
    }
    .mod-popup .option-active{
        background: #206ad3;
        color: #fff;
        font-weight: bold;
    }
    .mod-popup .border-line{
        width:100%;
        border-bottom: 2px solid #005dad;
        margin-bottom: 8px;
    }
    .icon-style {
        color: #00448b !important;
        bottom: 8px !important;
        font-size: 20px !important;
        float: right;
    }
    .border-notes {
        border-bottom: none !important;
    }
    .addon-note{
        height: 96px!important;
        border: 1px solid #b2b2b3!important;
        border-radius: 7px!important;
        padding: 10px 6px!important;
    }
    .addon-border{
        position: relative;
        bottom: 11px;
        border-bottom: 0.1px solid #b2b2b3;
    }
    .collapse-addon{
        position: relative;
        display: block;
        float: right;
        cursor: pointer;
    }
    .mod-popup .modifier-listing{
        list-style-type: disc;
        color: #fff;
        padding-left: 15px;
    }
    .mod-popup .h-100{
        height: 100%;
    }

    /* Radio button functionality */

    .mod-popup .form-check-label input[type=radio]~.radio-icon:before,
    .mod-popup .form-check-label input[type=radio]~.radio-icon:after{
        width: 14px !important;
        height: 14px !important;
        top: 14px !important;
        border-color: #00448b;
    }

    .mod-popup .form-check-label input[type=radio]:checked~.radio-icon:before{
        border-color: #00448b !important;
    }

    .mod-popup .form-check-label input[type=radio]:checked~.radio-icon:after{
        background-color: #00448b !important;
    }

    .mod-popup .form-check-label input[type=radio]:checked~.radio-icon:before{
        border-color: #00448b !important;
    }
    .mod-popup .image-unavailable {
        opacity: 0.6 !important;
        pointer-events: none;
        position: relative
    }
    .mod-popup .unavailable-image {
        opacity: 0.8 !important;
        position: absolute;
        vertical-align: middle;
        top: 50%;
        /* left: 42%; */
        transform: translate(-50%, -50%);
        color: red;
        font-weight: 600;
        height: 26px;
        line-height: 26px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.88);
        text-align: center;
    }
    .mod-popup .w-150px{
        width:150px;
    }
    .mod-popup .w-180px{
        width:180px;
    }
    .disableminus{
        opacity:0.5;
        pointer-events: none;
    }
    .mod-popup .unavail{
          position: absolute;
          bottom:0;
          right:5px;
     }
     .mod-popup .unavailoption {
          position: absolute;
          bottom:0;
          right:7px;
     }
	@media (max-width: 1024px) {
          .total-addon{
               overflow-x: scroll;
          }
     }
	.text-darker {
		color: #303031 !important;
	}
    .form-control.add-custom-addon {
          min-width: 36px !important;
     }
	@media (max-width: 540px) {
          .form-control.add-custom-addon {
               width: 36px !important;
               height: 34px !important;
          }
     }
	@media (max-width: 350px) {
          .form-control.add-custom-addon {
               width: 36px !important;
               height: 34px !important;
          }
     }
</style>